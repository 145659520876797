import React from 'react';
import Layout from '../components/Layout';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../components/Colors';
import { faIdCard } from '@fortawesome/free-solid-svg-icons';
// Each page will use <> wrapper to get global styles.
export default () => {
  return (
    <>
      <Layout
        jumbo={true}
        pageTitle="About Christian Bytes"
        pageDescription="Want to know more about Christian Bytes? Check out this page to get the quick info."
      >
        <h1
          css={css`
            color: ${Colors.main} !important;
            font-size: 2rem;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 2rem;
            border-bottom: 2px solid ${Colors.main};
          `}
        >
          <FontAwesomeIcon
            css={css`
              color: ${Colors.main} !important;
              margin-right: 0.5rem;
            `}
            icon={faIdCard}
            size={'sm'}
            width="28"
          />
          ABOUT & CONTACT
        </h1>
        <p>
          I started <strong>Christian Bytes</strong> as a way for you & me to
          live a fantastic CHRIST-CENTERED life.
        </p>
        <p>
          Above all, I want Christian Bytes to be a light in the darkness of the
          Internet so that more people not only find Jesus Christ, but those in
          Christ find more ways to live a CHRIST-CENTERED life.
        </p>
        <p>
          There's a wealth of Christian teachings, movies, tv, apps, software and
          sites out there that can help not only entertain us, but grow us in
          Christ.
        </p>
        <p>
          I want Christian Bytes to be a go-to source for you to find this
          wealth and to remain up-to-date with the latest & greatest Christian
          apps, software, tv, movie and more.
        </p>
        <p>
          A goal of Christian Bytes is to also provide exposure to great
          Christian offerings, some of which don't have the best marketing
          efforts.
        </p>
        <p>
          Additionally, I want to use this site as a way to help creators of
          Christian products & services fine-tune their offerings and make them
          even better.
        </p>
        <p>
          For more information about the launch of Christian Bytes, check out
          this post:
          <br />
          <a
            class="main-link"
            href="https://www.christianbytes.com/cb-updates/christian-bytes-launches"
          >
            Christian Bytes Launches
          </a>
        </p>
        <h2>Contact Christian Bytes</h2>
        <p>Got a question or comment? Want to see CB cover something?</p>
        <p>Please take a moment and send an email. </p>
        <p>
          Email:
          <br />
          <a class="main-link" href="mailto:readers@christianbytes.com">
            readers@christianbytes.com
          </a>
        </p>
      </Layout>
    </>
  );
};
